import React from 'react'
import Layout from '../components/layout'
import { Section, Heading, Text, AboutContainer } from '../styles/default-template.styled'
import PortableText from '../components/BlockContent/PortableText'
import { graphql } from 'gatsby' 
import SEO from '../components/seo'


export const query = graphql`
    query {
        sanityAbout {
            title
            _rawBody
            seo {
                description,
                image {
                  asset {
                    url
                  }
                }
              }
        
        }
    }
`

const WorkFieldTemplate = ({data}) => {
    const {title, _rawBody} = data.sanityAbout
    return (
        <Layout>
            <SEO seo={data.sanityAbout.seo} title={title} />
            <Section>
                <AboutContainer>
                    <Heading>{title}</Heading>
                    <Text>
                        <PortableText blocks={_rawBody} />
                    </Text>
                </AboutContainer>
            </Section>
        </Layout>
    )
}

export default WorkFieldTemplate